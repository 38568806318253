<template>
  <FormText
    title='Ошибка!'
    buttonText='Зарегистрироваться'
    buttonLink='/register'>
    <p>Ваша регистрация не&nbsp;подтверждена.</p>
    <p>Попробуйте <router-link to='/register'>зарегистрироваться</router-link>
    снова или воспользуйтесь авторизацией через
    <router-link to='/login'>социальные сервисы</router-link>.</p>
  </FormText>
</template>

<script>
import FormText from '@/components/ui/formText/formText.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'RegisterConfirmError',
  components: {
    FormText,
  },
  created() {
    document.title = 'Крибрум: Регистрация не подтверждена';
  },
};
</script>
