var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    form: true
  }, _obj["form--" + _vm.theme] = true, _obj )},[_c('form',{staticClass:"form__wrapper",on:{"submit":_vm.submit}},[_c('h3',{staticClass:"form__row form__title"},[_vm._v(_vm._s(_vm.title)+" "),(_vm.subtitle && _vm.subtitleLink)?_c('span',{staticClass:"form__link form__link--register",on:{"click":_vm.to}},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()]),(_vm.showSocial)?_c('SocialLogin',{staticClass:"form__row form__scl",attrs:{"theme":_vm.theme}}):_vm._e(),_c('div',{staticClass:"form__row",on:{"click":_vm.click}},[_vm._t("default")],2),(_vm.showForgotLink)?_c('router-link',{staticClass:"form__row form__link form__link--forgot",attrs:{"to":"/forgot"}},[_vm._v("Забыли пароль")]):_vm._e(),(_vm.showLogin)?_c('router-link',{staticClass:"form__row form__link form__link--forgot",attrs:{"to":"/login"}},[_vm._v("Вход")]):_vm._e(),_c('div',{class:{
        "form__row": true,
        "form__errors": true,
        "form__errors--is-active": _vm.errors,
      },domProps:{"innerHTML":_vm._s(_vm.errors)}}),_c('Button',{staticClass:"form__row form__submit login__submit",attrs:{"text":_vm.submitText,"width":"available","type":"submit"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }