/**
* Метод возвращает свойство компонента если оно есть и оно не пустое. Иначе возвращает false.
* В случае если свойство имеет зависимость, напимер placeholder зависит
* от свойства placeholder и showPlaceholder, то его можно указать в параметре addProps
* @param {string} propName - название свойства
* @param {string} addProps - название свойства от которого зависит первое
* @returns {any} - возвращает либо свойства либо false в случае его отсутствия
*/
export default {
  methods: {
    getProperty(propName, addProps = false) {
      const isFieldNonEmpty = !!(this[propName] && this[propName].length > 0);
      if (!addProps) {
        return isFieldNonEmpty ? this[propName] : false;
      }
      return (isFieldNonEmpty && this[addProps]) ? this[propName] : false;
    },
  },
};
