<template>
  <div
    :class='{
      form: true,
      ["form--" + theme]: true,
    }'>
    <form class='form__wrapper' @submit='submit'>
      <h3 class='form__row form__title'>{{title}}
        <span
          class='form__link form__link--register'
          v-if='subtitle && subtitleLink'
          @click='to'>{{subtitle}}</span>
      </h3>
      <SocialLogin
        class='form__row form__scl'
        v-if='showSocial'
        :theme='theme'/>
      <div class='form__row' @click='click'>
        <slot/>
      </div>
      <router-link
        class='form__row form__link form__link--forgot'
        to='/forgot'
        v-if='showForgotLink'>Забыли пароль</router-link>
      <router-link
        class='form__row form__link form__link--forgot'
        to='/login'
        v-if='showLogin'>Вход</router-link>
      <div
        :class='{
          "form__row": true,
          "form__errors": true,
          "form__errors--is-active": errors,
        }'
        v-html='errors'>
      </div>
      <Button
        class='form__row form__submit login__submit'
        :text='submitText'
        width='available'
        type='submit'
      />
    </form>
  </div>
</template>

<script>
import Button from '@/components/ui/button/button.vue';
import SocialLogin from '@/components/ui/socialLogin/socialLogin.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'Form',
  components: {
    Button,
    SocialLogin,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitleLink: {
      type: String,
      default: '',
    },
    showForgotLink: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: 'Войти',
    },
    errors: {
      type: String,
      default: '',
    },
    showSocial: {
      type: Boolean,
      default: true,
    },
    showLogin: {
      type: Boolean,
      default: false,
    },
    /**
    * Тема компонента
    */
    theme: {
      type: String,
      default: 'on-white',
      validator: value => ['on-white', 'on-color'].includes(value),
    },
    transition: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$emit('submit');
    },
    click(e) {
      e.preventDefault();
      this.$emit('click');
    },
    to() {
      if (this.transition) {
        this.$router.push(this.subtitleLink);
        return;
      }
      this.$emit('to');
    },
  },
};
</script>
<style lang='scss' scoped>
$block: '.form';
#{$block} {
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  &__wrapper {
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: 30px;
    font-weight: 300;
    line-height: 42px;
    position: relative;
  }
  &__link {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-decoration: none;
    cursor: pointer;
    &--register {
      position: absolute;
      right: 0;
    }
    &--forgot {
      display: block;
    }
  }
  &__errors {
    max-height: 0;
    overflow: hidden;
    color: #f42928;
    font-size: 16px;
    font-weight: 400;
    transition: max-height 0.5s ease;
    &--is-active {
      max-height: 500px;
    }
  }
  &__submit {
    display: block;
  }
}

#{$block}--on-white {
  border-color: #e4e4e4;
  #{$block}{
    &__title,
    &__description
    &__scl {
      color: #243843;
    }
    &__link {
      color: #D54140;
      &--forgot {
        color: #5A7380;
      }
    }
  }
}

#{$block}--on-color {
  border-color: #ffffff;
  #{$block} {
    &__title,
    &__description,
    &__scl {
      color: #ffffff;
    }
    &__link {
      color: #D54140;
      &--forgot {
        color: #ffffff;
      }
    }
  }
}

@media all {
  #{$block} {
    width: 280px;
    &__wrapper {
      padding: 16px;
    }
    &__row,
    &__field {
      margin: 16px 0;
    }
  }
}
@media all and (min-width: 640px) {
  #{$block} {
    width: 380px;
    &__wrapper {
      padding: 32px;
    }
  }
}
</style>
