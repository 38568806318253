<template>
  <div class='v-page'>
    <div class='v-page__wrapper' v-html='content'>
    </div>
    <Loader
      :class='{
        "v-page__loader": true,
        "v-page__loader--is-loading": true,
      }'
      v-if='isLoad'/>
  </div>
</template>

<script>
import axios from 'axios';
import Loader from '@/components/ui/loader/loader.vue';
/**
 * Компонент виртуальной страницы.
 *
 */
export default {
  name: 'VirtualPage',
  created() {
    this.getContent(this.$route.fullPath);
  },
  components: {
    Loader,
  },
  data() {
    return {
      isLoad: true,
      content: '',
    };
  },
  methods: {
    getContent(page) {
      const url = `/pages${page}.html`;
      return axios.get(url)
        .then((res) => {
          this.content = res.data;
          this.isLoad = false;
        })
        .catch((err) => {
          this.isLoad = false;
          console.error(err.message);
        });
    },
  },
};
</script>
<style lang='scss'>
  .v-page {
    width: 100%;
    min-height: 36px;
    margin: 24px auto;
    &__wrapper {
      padding: 0 16px;
    }
    &__loader {
      position: absolute;
      top: calc(50% - 18px);
      left: calc(50% - 18px);
    }
    section {
      color: #5c5d5e;
    }
    h1 {
      color: #313236;
      font-size: 30px;
      line-height: 1.4;
    }
    h2 {
      color: #313236;
      font-size: 22px;
      line-height: 30px;
    }
    h3 {
      color: #313236;
      font-size: 18px;
      line-height: 26px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    section a {
      color: #5c5d5e;
      font-weight: 700;
      text-decoration: none;
      position: relative;
      &:before {
        width: 100%;
        height: 1px;
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        background-color: #5c5d5e;
        content: ' ';
      }
    }
    ul {
      list-style: none;
      li {
        list-style-type: none;
        position: relative;
        &:before {
          width: 6px;
          height: 6px;
          display: block;
          position: absolute;
          top: 8px;
          left: -22px;
          background-color: #5c5d5e;
          border-radius: 3px;
          content: ' ';
        }
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    ol {
      list-style: none;
      counter-reset: count;
      li {
        list-style-type: none;
        position: relative;
        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: -22px;
          border-radius: 3px;
          counter-increment: count;
          content: counter(count);
        }
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  @media all {
    .v-page {
      section {
        margin: 32px 0;
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      section > * {
        margin: 8px 0;
      }
      h1 {
        margin-bottom: 16px;
      }
      h2 {
        padding: 8px 0;
        border-bottom: 1px solid #e7e9ed;
      }
      h3 {
        margin: 16px 0 8px 0;
      }
      ul,
      ol {
        margin: 16px 0;
        padding-left: 32px;
        li {
          margin: 4px 0;
        }
      }
    }
  }
  @media all and (min-width: 960px) {
    .v-page {
      margin: 32px auto;
    }
  }
  @media all and (min-width: 1366px) {
    .v-page__wrapper {
      padding: 0;
    }
  }
</style>
