var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    "sn-login": true
  }, _obj["sn-login--" + _vm.theme] = true, _obj )},[_c('div',{staticClass:"sn-login__wrapper"},[_c('span',{staticClass:"sn-login__text"},[_vm._v("Через :")]),_c('ul',{staticClass:"sn-login__list"},_vm._l((_vm.socials),function(social){
  var _obj;
return _c('li',{key:social.name,staticClass:"sn-login__item"},[_c('a',{class:( _obj = {
            "sn-login__link": true
          }, _obj["sn-login__link--" + social.name] = true, _obj ),attrs:{"href":_vm.getUrl(social.name)}},[_c('Icon',{staticClass:"sn-login__logo",attrs:{"icon":social.name}})],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }