<template>
  <div class='page404'>
    <div class='page404__wrapper'>
      <Icon404 class='page404__number'/>
      <div class='page404__column'>
        <h1 class='page404__title'>Ничего<br> не найдено</h1>
        <div class='page404__text'>
          <p>Такой страницы не существует</p>
          <p>Давайте найдем другую</p>
        </div>
      </div>
      <router-link class='page404__link' to='/'>На главную</router-link>
    </div>
  </div>
</template>

<script>
import Icon404 from '@/img/svg/icon-404.svg';
/**
 * Компонент страница не найдена (404)
 */
export default {
  name: 'Page404',
  components: {
    Icon404,
  },
  created() {
    document.title = 'Крибрум: Страница не найдена';
  },
};
</script>

<style scoped lang="scss">
  $block: '.page404';

  #{$block} {
    margin: 0 auto;
    &__wrapper {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
    }
    &__number {
      color: #4c7482;
      font-size: 130px;
    }
    &__title {
      color: #ffffff;
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
    }
    &__text {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
    &__link {
      width: 240px;
      margin: 0 auto;
      padding: 12px 10px;
      color: #ffffff;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      text-decoration: none;
      border-radius: 5px;
      background-color: #f63f3e;

    }
  }
  @media all {
    #{$block} {
      margin: 24px auto;
      &__number {
        margin-bottom: 24px;
      }
      &__title {
        margin-top: 24px;
        margin-bottom: 10px;
      }
      &__link {
        margin-top: 24px;
      }
    }
  }
  @media all and (min-width: 960px) {
    #{$block} {
      width: 600px;
      margin: 32px auto;
      &__wrapper {
        flex-flow: row wrap;
      }
      &__number {
        margin-bottom: 0;
      }
      &__title {
        margin-top: 0;
      }
      &__link {
        margin-top: 32px;
      }
    }
  }
</style>
