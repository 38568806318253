<template>
  <FormText
    title='Запущена процедура восстановления пароля'
    buttonText='На главную'
    buttonLink='/'>
    <p>
      На&nbsp;указанный вами адрес электронной почты отправлено письмо
      со&nbsp;ссылкой для подтверждения замены пароля.</p>
    <p>
      Вам необходимо перейти по&nbsp;этой ссылке,
      чтобы подтвердить восстановление пароля.</p>
  </FormText>
</template>

<script>
import FormText from '@/components/ui/formText/formText.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'ForgotSuccess',
  components: {
    FormText,
  },
  created() {
    document.title = 'Крибрум: Восстановление пароля';
  },
};
</script>
