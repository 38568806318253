<template>
  <div>
    <Form
      class='login'
      title='Вход'
      subtitle='Регистрация'
      subtitleLink='/register'
      :showForgotLink='true'
      :errors='errors'
      @submit='submit'>
      <Field
        class='form__field'
        placeholder='E-mail'
        name='email'
        type='text'
        theme='on-color'
        :error='err.email'
        v-model='email'
        @click='clearFieldError("email")'/>
      <Field
        class='form__field'
        placeholder='Пароль'
        name='password'
        type='password'
        theme='on-color'
        :error='err.password'
        v-model='password'
        @click='clearFieldError("email")'/>
    </Form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/components/ui/form/form.vue';
import Field from '@/components/ui/field/field.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'Login',
  components: {
    Form,
    Field,
  },
  created() {
    document.title = 'Крибрум: Авторизация';
  },
  data() {
    return {
      email: '',
      password: '',
      errors: '',
      err: {
        email: false,
        password: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getPrevPath',
    ]),
  },
  methods: {
    clearFieldError(field) {
      this.err[field] = false;
    },
    clearErrors() {
      this.errors = '';
    },
    submit() {
      let err = '';
      const isEmailExist = this.email && this.email.length;
      if (!isEmailExist) {
        err += 'Поле "E-mail" обязательно для заполнения<br>';
        this.err.email = true;
      }
      if (!this.password || !this.password.length) {
        err += 'Поле "Пароль" обязательно для заполнения<br>';
        this.err.password = true;
      }
      if (this.password.length < 6 && !(!this.password || !this.password.length)) {
        err += 'Длина пароля должна быть не менее 6 символов<br>';
        this.err.password = true;
      }

      /* eslint-disable-next-line */
      const isEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email);
      if (!isEmail && isEmailExist) {
        err += 'Поле "E-mail" должно содержать e-mail<br>';
        this.err.email = true;
      }

      if (err) {
        this.errors = err;
        return false;
      }

      this.$store.dispatch('login', {
        login: this.email,
        password: this.password,
      })
        .then(() => {
          this.$store.dispatch('userInfo');
          this.clearErrors();
          this.clearFieldError('email');
          this.clearFieldError('password');

          const toRoute = this.getPrevPath && this.getPrevPath.length > 0 ? this.getPrevPath : '/main';
          this.$router.push(toRoute);
        })
        .catch((erro) => {
          this.errors = erro;
          this.err.password = true;
        });
    },
  },
};
</script>
<style>
  .login {
    margin: 24px auto;
  }
  @media all and (min-width: 960px) {
    .login {
      margin: 32px auto;
    }
  }
</style>
