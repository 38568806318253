<template>
  <Form
    class='forgot'
    title='Изменение пароля'
    submitText='Изменить'
    :showSocial='false'
    :errors='errors'
    @submit='submit'>
      <div class='form__description form__text'>
        <p>Введите желаемый пароль.<br>Пароль должен содержать не менее 6 символов</p>
      </div>
      <Field
        class='form__field'
        placeholder='Пароль'
        name='password'
        type='password'
        theme='on-color'
        :error='err.password'
        v-model='password'
        @click='clearFieldError("password")'/>
      <Field
        class='form__field'
        placeholder='Подтвердите пароль'
        name='password2'
        type='password'
        theme='on-color'
        :error='err.password2'
        v-model='password2'
        @click='clearFieldError("password2")'/>
  </Form>
</template>

<script>
import Form from '@/components/ui/form/form.vue';
import Field from '@/components/ui/field/field.vue';
/**
 * Компонент забыли пароль.
 *
 */
export default {
  name: 'Forgot',
  components: {
    Form,
    Field,
  },
  created() {
    document.title = 'Крибрум: Изменение пароля';
  },
  data() {
    return {
      password: '',
      password2: '',
      errors: '',
      err: {
        password: false,
        password2: false,
      },
    };
  },
  methods: {
    clearErrors() {
      this.errors = '';
    },
    clearFieldError(field) {
      this.err[field] = false;
    },
    submit() {
      let err = '';
      const isPasswodExist = !(!this.password || !this.password.length);
      const isConfirmPasswordExist = !(!this.password2 || !this.password2.length);
      if (!isPasswodExist) {
        err += 'Поле "Пароль" обязательно для заполнения<br>';
        this.err.password = true;
      }
      if (!isConfirmPasswordExist) {
        err += 'Поле "Подтвердите пароль" обязательно для заполнения<br>';
        this.err.password2 = true;
      }

      if (
        (this.password.length < 6 && isPasswodExist) ||
        (this.password2.length < 6 && isConfirmPasswordExist)
      ) {
        err += 'Длина пароля должна быть не менее 6 символов<br>';
        this.err.password2 = true;
      }

      if (this.password !== this.password2) {
        err += 'Введенные пароли не совпадают<br>';
        this.err.password2 = true;
      }

      if (err) {
        this.errors = err;
        return false;
      }

      return this.$store.dispatch('changePassword', {
        password: this.password,
      })
        .then(() => {
          this.clearErrors();
          this.clearFieldError('password');
          this.clearFieldError('password2');
          this.$router.push('/forgot/confirm-success');
        })
        .catch((erro) => {
          this.errors = erro;
          this.err.password = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .forgot {
    margin: 24px auto;
  }
  @media all and (min-width: 960px) {
    .forgot {
      margin: 32px auto;
    }
  }
</style>
