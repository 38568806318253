<template>
  <div class='form'>
    <form class='form__wrapper'>
      <h3 class='form__title'>{{title}}</h3>
      <div class='form__description'>
        <slot/>
      </div>
      <router-link :to='buttonLink'>
        <Button
          class='form__submit'
          :text='buttonText'
          width='available'
          type='button'
        />
      </router-link>
    </form>
  </div>
</template>

<script>
import Button from '@/components/ui/button/button.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'FormText',
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$emit('submit');
    },
    click(e) {
      e.preventDefault();
      this.$emit('click');
    },
  },
};
</script>
<style lang='scss'>
  @import './formText.scss';
</style>
