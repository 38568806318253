<template>
  <div
    :class='{
      field: true,
      ["field--" + theme]: true,
    }'
    @click='$emit("click", $event)'>
    <input
      :class='{
        field__input: true,
        "field__input--invalid": error,
      }'
      :placeholder='getProperty("placeholder")'
      :name='getProperty("name")'
      :type='getProperty("type")'
      v-model='inputValue'/>
    <div class='field__error error' v-if='error'>
      <div class='error__wrapper'>
        <Icon class='error__icon' icon='exclamation'/>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon/icon';
import getPropertyMixin from '@/mixins/getProperty';
/**
 * Компонент поля.
 *
 */
export default {
  name: 'Field',
  mixins: [getPropertyMixin],
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
    },
    error: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * Тип поля
     */
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'password', 'email'].includes(value),
    },
    /**
    * Тема компонента
    */
    theme: {
      type: String,
      default: 'on-white',
      validator: value => ['on-white', 'on-color'].includes(value),
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>
<style lang='scss' scoped>
$block: '.field';
#{$block} {
  position: relative;
  &__input {
    width: 100%;
    padding: 6px 44px 6px 20px;
    display: block;
    color: #6c6c6c;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 4px;
    border: 1px solid transparent;
    outline: none;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
  }
  &__error {
    position: absolute;
    top: 0;
    right: 0;
  }
}

#{$block}--on-white {

}

#{$block}--on-color {
  #{$block}__input {
    border: 1px solid #e4e4e4;
    &--invalid {
      border: 1px solid #ff7373;
    }
  }
}

.error {
  padding: 2px 8px 2px 16px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.5s ease;
  cursor: pointer;
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
  }
  &__icon {
    width: 28px;
    flex: 0 0 auto;
    fill: #ff7373;
  }
  &__text {
    color: #fff;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 400;
    opacity: 0;
  }
  /*
  &:hover {
    background-color: #ff7373;
    .error__icon {
      fill: #ffffff;
    }
    .error__text {
      opacity: 1;
    }
  }
  */
}
</style>
