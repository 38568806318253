<template>
  <FormText
    title='Успешное изменение пароля'
    buttonText='На главную'
    buttonLink='/'>
    <p>Ваш пароль успешно изменен!</p>
  </FormText>
</template>

<script>
import FormText from '@/components/ui/formText/formText.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'ForgotConfirmSuccess',
  components: {
    FormText,
  },
  created() {
    document.title = 'Крибрум: Восстановление пароля успешно завершено';
  },
};
</script>
