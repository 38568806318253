<template>
  <FormText
    title='Вы успешно зарегистрированы'
    buttonText='На главную'
    buttonLink='/main'>
    <p>На&nbsp;адрес электронной почты, который вы&nbsp;указали при регистрации,
      отправлено письмо со&nbsp;ссылкой для подтверждения регистрации.</p>
    <p>Вам необходимо перейти по&nbsp;этой ссылке, чтобы завершить регистрацию.</p>
  </FormText>
</template>

<script>
import FormText from '@/components/ui/formText/formText.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'RegisterSuccess',
  components: {
    FormText,
  },
  created() {
    document.title = 'Крибрум: Регистрация успешно завершена';
  },
};
</script>
