var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    field: true
  }, _obj["field--" + _vm.theme] = true, _obj ),on:{"click":function($event){return _vm.$emit("click", $event)}}},[((_vm.getProperty("type"))==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:{
      field__input: true,
      "field__input--invalid": _vm.error,
    },attrs:{"placeholder":_vm.getProperty("placeholder"),"name":_vm.getProperty("name"),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.getProperty("type"))==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:{
      field__input: true,
      "field__input--invalid": _vm.error,
    },attrs:{"placeholder":_vm.getProperty("placeholder"),"name":_vm.getProperty("name"),"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:{
      field__input: true,
      "field__input--invalid": _vm.error,
    },attrs:{"placeholder":_vm.getProperty("placeholder"),"name":_vm.getProperty("name"),"type":_vm.getProperty("type")},domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value}}}),(_vm.error)?_c('div',{staticClass:"field__error error"},[_c('div',{staticClass:"error__wrapper"},[_c('Icon',{staticClass:"error__icon",attrs:{"icon":"exclamation"}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }