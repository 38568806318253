<template>
  <div class='loader'>
    <svg class='loader__wrapper'>
      <g :fill='color'>
        <!-- eslint-disable max-len -->
        <path
          class="loader__part loader__part--1"
          d="M30.108 3.106S24.746.1 20.575 0c-.695 0-1.29 0-1.787.2-2.582.702-5.76 6.313-7.05 9.52-.894 2.304-.994 3.807-.2 4.208.398.2 1.093 0 2.086-.802l.1-.1c.794-.802 5.759-6.212 7.249-6.814 1.588-.6 10.03.702 10.03.702 2.978 0 2.581-1.804-.895-3.808z"/>
        <path
          class="loader__part loader__part--2"
          d="M35.584 14.958c-.196-.712-.392-1.321-.686-1.728-1.373-2.338-7.55-3.862-10.883-4.167-2.255-.204-3.627.101-3.921.711-.295.508.098 1.22 1.274 2.033 0 0 .098 0 .098.102.98.508 7.451 3.964 8.432 5.183.98 1.423 2.157 10.063 2.157 10.063.882 3.05 2.549 2.236 3.333-1.83-.098-.101 1.275-6.2.196-10.367z"/>
        <path
          class="loader__part loader__part--3"
          d="M29.31 20.521c-.493-2.145-1.182-3.316-1.872-3.51-.492-.098-1.083.487-1.476 1.755v.097c-.197 1.073-1.28 8.192-2.068 9.46-.985 1.365-8.566 4.973-8.566 4.973-2.56 1.755-1.28 3.023 2.658 2.633 0 0 6.105-.487 9.55-2.828.592-.39 1.084-.78 1.38-1.17 1.673-1.95 1.082-8.192.393-11.41z"/>
        <path
          class="loader__part loader__part--4"
          d="M21.914 26.242c-.197-.408-.887-.713-2.07-.611h-.099c-1.084.203-8.281 1.325-9.76 1.019-1.577-.51-7.493-6.83-7.493-6.83C.028 17.883-.76 19.514.816 23.286c0 0 2.366 5.81 5.718 8.461.493.51 1.085.816 1.578 1.02 2.465 1.02 8.38-1.53 11.239-3.263 1.972-1.427 2.958-2.65 2.563-3.262z"/>
        <path
          class="loader__part loader__part--5"
          d="M8.45 12.414c0-1.666 4.223-9.013 4.223-9.013C13.78.462 11.97.168 8.853 2.813c0 0-4.726 3.919-6.335 7.74-.302.685-.402 1.273-.503 1.763-.301 2.645 3.922 7.543 6.435 9.797 1.81 1.665 3.218 2.253 3.821 1.665.302-.294.402-1.078 0-2.155l-.1-.098c-.302-1.078-3.62-7.642-3.72-9.111z"/>
        <!-- eslint-enable max-len -->
      </g>
    </svg>
  </div>
</template>

<script>
/**
 * Компонент показывающий кольцо загрузки.
 */
export default {
  name: 'Loader',
  props: {
    /**
     * Цвет кольца
     */
    color: {
      type: String,
      default: '#848484',
    },
  },
};
</script>

<style scoped lang="scss">

@keyframes loader {
  0%, 80%, 100% {
    opacity: 0;
  } 40% {
    opacity: 1;
  }
}

.loader{
  width: 36px;
  height: 36px;
  &__wrapper {
    width: 36px;
    height: 36px;
    display: block;
    position: relative;
  }
  &__part {
    position: absolute;
    animation: loader 2s infinite linear both;
    &--1 {
      width: 22px;
      height: 14px;
      top: 0;
      left: 11px;
      animation-delay: -1.75s;
    }
    &--2 {
      width: 16px;
      height: 20px;
      top: 9px;
      right: 0;
      animation-delay: -1.5s;
    }
    &--3 {
      width: 16px;
      height: 19px;
      left: 14px;
      bottom: 0;
      animation-delay: -1s;
    }
    &--4 {
      width: 22px;
      height: 14px;
      top: 19px;
      left: 0;
      animation-delay: -0.5s;
    }
    &--5 {
      width: 11px;
      height: 23px;
      top: 1px;
      left: 2px;
      animation-delay: -0.1s;
    }
  }
}
</style>
