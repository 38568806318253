<template>
  <FormText
    title='Ошибка!'
    buttonText='Войти'
    buttonLink='/login'>
    <p>Авторизация через социальный сервис не&nbsp;удалась.</p>
    <p>Попробуйте <router-link to='/register'>войти</router-link> еще раз или
    <router-link to='/register'>зарегистрируйтесь</router-link> в&nbsp;нашем сервисе.</p>
  </FormText>
</template>

<script>
import FormText from '@/components/ui/formText/formText.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'RegisterConfirmSocialError',
  components: {
    FormText,
  },
  created() {
    document.title = 'Крибрум: Авторизация не удалась';
  },
};
</script>
