<template>
  <FormText
    title='Восстановление пароля не подтверждено'
    buttonText='Восстановление пароля'
    buttonLink='/forgot'>
    <p>Вероятно, истек срок действия ссылки для проверки восстановления пароля.</p>
    <p>Попробуйте снова запросить <router-link to='/forgot'>восстановление пароля</router-link>.</p>
  </FormText>
</template>

<script>
import FormText from '@/components/ui/formText/formText.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'ForgotConfirmError',
  components: {
    FormText,
  },
  created() {
    document.title = 'Крибрум: Восстановление пароля не подтверждено';
  },
};
</script>
