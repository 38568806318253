<template>
  <div class='main'>
    <div class='main__wrapper'>
      <div class='main__column main-block'>
        <div class='main-block__title-and-img'>
          <IconRating class='main-block__img'/>
          <h3 class='main-block__title'>Рейтинг <br/>авторов</h3>
        </div>
        <ul class='main-block__list list'>
          <li class='list__item'>Рейтинг популярных авторов</li>
          <li class='list__item'>Рейтинги по&nbsp;соцсетям,<br> сообществам и&nbsp;людям</li>
          <li class='list__item'>Динамика изменения <br>популярности автора</li>
          <li class='list__item'>Поиск автора и&nbsp;его записей</li>
        </ul>
        <a class='main-block__link link' href="/rating" @click.prevent="$router.push({path: '/rating'})">
          <div class='link__wrapper'>
            <span class='link__text'>Открыть</span>
            <IconArrowRight class='link__icon' width="8px" height="13.3px"/>
          </div>
        </a>
      </div>
      <div class='main__column main__column--border'></div>
      <div class='main__column main-block'>
        <div class='main-block__title-and-img'>
          <IconStatistic class='main-block__img'/>
          <h3 class='main-block__title'>Статистика <br/>фраз</h3>
        </div>
        <ul class='main-block__list list'>
          <li class='list__item'>Оценка популярности <br>интересных вам фраз</li>
          <li class='list__item'>Сравнение их&nbsp;популярности <br>между собой</li>
          <li class='list__item'>История статистики</li>
        </ul>
        <a class='main-block__link link' href="/statistic" @click.prevent="$router.push({path: '/statistic'})">
          <div class='link__wrapper'>
            <span class='link__text'>Открыть</span>
            <IconArrowRight class='link__icon' width="8px" height="13.3px"/>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IconRating from '@/img/svg/icon-rating.svg';
import IconStatistic from '@/img/svg/icon-statistic.svg';
import IconArrowRight from '@/img/svg/icon-arrow-right.svg';
/**
 * Компонент главной страницы сайта
 */
export default {
  name: 'Main',
  components: {
    IconRating,
    IconStatistic,
    IconArrowRight,
  },
  created() {
    document.title = 'Крибрум: Поиск по социальным сетям';
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss">
  @import './main.scss';
  @import './main-block.scss';
  @import './list.scss';
  @import './link.scss';
</style>
