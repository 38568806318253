<template>
  <div
    :class='{
      "sn-login": true,
      ["sn-login--" + theme]: true,
    }'>
    <div class='sn-login__wrapper'>
      <span class='sn-login__text'>Через&nbsp;:</span>
      <ul class='sn-login__list'>
        <li
          class='sn-login__item'
          v-for='social in socials'
          :key='social.name'>
          <a
            :class='{
              "sn-login__link": true,
              ["sn-login__link--" + social.name]: true,
            }'
            :href='getUrl(social.name)'>
            <Icon
              class='sn-login__logo'
              :icon='social.name'/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon/icon';
/**
 * Компонент входа через соцсети.
 *
 */
export default {
  name: 'SocialLogin',
  components: {
    Icon,
  },
  props: {
    theme: {
      type: String,
      default: 'on-white',
      validator: value => ['on-white', 'on-color'].includes(value),
    },
  },
  computed: {
    backUrl() {
      return this.$store.getters.getPrevPath;
    },
  },
  methods: {
    getUrl(provider) {
      let url = `/auth/${provider}`;
      if (this.backUrl && this.backUrl.length > 0) {
        url += `?return_url=${encodeURIComponent(this.backUrl)}`;
      }
      return url;
    },
  },
  data() {
    return {
      socials: [{
        name: 'facebook',
      }, {
        name: 'vkontakte',
      }, {
        name: 'twitter',
      }, {
        name: 'yandex',
      }],
    };
  },
};
</script>

<style scoped lang="scss">
$block: '.sn-login';


#{$block} {
  color: #1a1a1a;
  &__text {
    font-size: 16px;
    font-weight: 400;
  }
  &__list {
    margin: 0;
    padding: 0;
    &:after {
      display: block;
      clear: both;
      content: ' ';
    }
  }
  &__item {
    float: left;
    list-style-type: none;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    &--vkontakte {
      background-color: #4c7793;
    }
    &--facebook {
      background-color: #44699d;
    }
    &--twitter {
      background-color: #2a90dc;
    }
    &--yandex {
      background-color: #ffffff;
    }
  }
  &__logo {
    display: block;
    fill: #ffffff;
  }
}

#{$block}--on-white {
  #{$block} {
    &__link--yandex {
      background-color: #ffdb4d;
    }
  }
}
#{$block}--on-dark {
  #{$block} {
    color: #ffffff;
  }
}

@media all {
  #{$block} {
    &__text {
      display: block;
      margin: 0 0 8px 0;
    }
    &__item {
      margin-right: 10px;
    }
    &__link {
      width: 40px;
      height: 40px;
    }
    &__logo {
      width: 20px;
      height: 20px;
    }
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &__text {
      margin: 0 16px 0 0;
    }
  }
}
</style>
