<template>
  <FormText
    title='Поздравляем!'
    buttonText='Искать'
    buttonLink='/search'>
    <p>Ваша регистрация в&nbsp;сервисе Публичный поиск подтверждена.</p>
    <p>Теперь вы&nbsp;можете делать <router-link to='/search'>поиск</router-link>
    с&nbsp;помощью нашего сервиса.</p>
  </FormText>
</template>

<script>
import FormText from '@/components/ui/formText/formText.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'RegisterConfirmSuccess',
  components: {
    FormText,
  },
  created() {
    document.title = 'Крибрум: Регистрация успешно подтверждена';
  },
};
</script>
