<template>
  <Form
    class='reg'
    title='Регистрация'
    subtitle='Вход'
    subtitleLink='/login'
    submitText='Зарегистрироваться'
    :errors='errors'
    :showSocial='false'
    @submit='submit'>
      <Field
        class='form__field'
        placeholder='E-mail'
        name='email'
        type='text'
        theme='on-color'
        :error='err.email'
        v-model='email'
        @click='clearFieldError("email")'/>
      <Field
        class='form__field'
        placeholder='Пароль'
        name='password'
        type='password'
        theme='on-color'
        :error='err.password'
        v-model='password'
        @click='clearFieldError("password")'/>
      <Field
        class='form__field'
        placeholder='Подтвердите пароль'
        name='password2'
        type='password'
        theme='on-color'
        :error='err.password2'
        v-model='password2'
        @click='clearFieldError("password2")'/>
  </Form>
</template>

<script>
import Form from '@/components/ui/form/form.vue';
import Field from '@/components/ui/field/field.vue';
/**
 * Компонент входа.
 *
 */
export default {
  name: 'Register',
  components: {
    Form,
    Field,
  },
  created() {
    document.title = 'Крибрум: Регистрация';
  },
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      errors: '',
      err: {
        email: false,
        password: false,
        password2: false,
      },
    };
  },
  methods: {
    clearFieldError(field) {
      this.err[field] = false;
    },
    clearErrors() {
      this.errors = '';
    },
    submit() {
      let err = '';
      const isEmailExist = this.email && this.email.length;
      /* eslint-disable-next-line */
      const isEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email);

      if (!isEmailExist) {
        err += 'Поле "E-mail" обязательно для заполнения<br>';
        this.err.email = true;
      }
      if (!isEmail && isEmailExist) {
        err += 'Поле "E-mail" должно содержать e-mail<br>';
        this.err.email = true;
      }
      if (!this.password || !this.password.length) {
        err += 'Поле "Пароль" обязательно для заполнения<br>';
        this.err.password = true;
      }
      if (!this.password2 || !this.password2.length) {
        err += 'Поле "Подтвердите пароль" обязательно для заполнения<br>';
        this.err.password2 = true;
      }
      if (
        (this.password.length < 6 && !(!this.password || !this.password.length)) ||
        (this.password2.length < 6 && !(!this.password2 || !this.password2.length))
      ) {
        err += 'Длина пароля должна быть не менее 6 символов<br>';
        this.err.password2 = true;
      }

      if (this.password !== this.password2) {
        err += 'Введенные пароли не совпадают<br>';
        this.err.password2 = true;
      }

      if (err) {
        this.errors = err;
        return false;
      }

      return this.$store.dispatch('register', {
        login: this.email,
        password: this.password,
      })
        .then(() => {
          this.clearErrors();
          this.clearFieldError('email');
          this.clearFieldError('password');
          this.clearFieldError('password2');
          this.$router.push('/register/success');
        })
        .catch((erro) => {
          this.err.password2 = true;
          this.errors = erro;
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .reg {
    margin: 24px auto;
  }
  @media all and (min-width: 960px) {
    .reg {
      margin: 32px auto;
    }
  }
  .password {
    position: relative;
    a {
      width: 16px;
      height: 20px;
      display: block;
      position: absolute;
      right: 20px;
      top: calc(50% - 10px);
      background-image: url("data:image/svg+xml,%3C%3Fxml%20version=%271.0%27%20encoding=%27UTF-8%27%3F%3E%3Csvg%20width=%2720px%27%20height=%278px%27%20viewBox=%270%200%2020%208%27%20version=%271.1%27%20xmlns=%27http://www.w3.org/2000/svg%27%20xmlns:xlink=%27http://www.w3.org/1999/xlink%27%3E%3Cg%20id=%27Page-1%27%20stroke=%27none%27%20stroke-width=%271%27%20fill=%27none%27%20fill-rule=%27evenodd%27%3E%3Cg%20id=%27iPhone-SE-Copy-12%27%20transform=%27translate%28-271.000000,%20-339.000000%29%27%3E%3Cg%20id=%27eye-closed%27%20transform=%27translate%28271.000000,%20340.000000%29%27%3E%3Cg%20id=%27Group-10%27%3E%3Cg%20id=%27Group-12%27%3E%3Cpath%20d=%27M18.4852814,3.51471863%20C16.3137085,1.34314575%2013.3137085,0%2010,0%20C6.6862915,0%203.6862915,1.34314575%201.51471863,3.51471863%27%20id=%27Oval-3%27%20stroke=%27%23000000%27%20stroke-width=%272%27%20transform=%27translate%2810.000000,%201.757359%29%20scale%281,%20-1%29%20translate%28-10.000000,%20-1.757359%29%20%27%3E%3C/path%3E%3Crect%20id=%27Rectangle%27%20fill=%27%23000000%27%20x=%279%27%20y=%273%27%20width=%272%27%20height=%274%27%20rx=%271%27%3E%3C/rect%3E%3Crect%20id=%27Rectangle%27%20fill=%27%23000000%27%20transform=%27translate%283.000000,%203.000000%29%20rotate%2840.000000%29%20translate%28-3.000000,%20-3.000000%29%20%27%20x=%272%27%20y=%271%27%20width=%272%27%20height=%274%27%20rx=%271%27%3E%3C/rect%3E%3Crect%20id=%27Rectangle%27%20fill=%27%23000000%27%20transform=%27translate%2817.000000,%203.000000%29%20rotate%28-40.000000%29%20translate%28-17.000000,%20-3.000000%29%20%27%20x=%2716%27%20y=%271%27%20width=%272%27%20height=%274%27%20rx=%271%27%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-color: #0000;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      &.show {
        background-image: url("data:image/svg+xml,%3C%3Fxml%20version=%271.0%27%20encoding=%27UTF-8%27%3F%3E%3Csvg%20width=%2720px%27%20height=%2711px%27%20viewBox=%270%200%2020%2011%27%20version=%271.1%27%20xmlns=%27http://www.w3.org/2000/svg%27%20xmlns:xlink=%27http://www.w3.org/1999/xlink%27%3E%3Cg%20id=%27Page-1%27%20stroke=%27none%27%20stroke-width=%271%27%20fill=%27none%27%20fill-rule=%27evenodd%27%3E%3Cg%20id=%27iPhone-SE-Copy-11%27%20transform=%27translate%28-271.000000,%20-339.000000%29%27%20stroke-width=%272%27%20stroke=%27%23000000%27%3E%3Cg%20id=%27eye-open%27%20transform=%27translate%28272.000000,%20340.000000%29%27%3E%3Cg%20id=%27Group-10%27%3E%3Ccircle%20id=%27Oval-2%27%20cx=%279%27%20cy=%276%27%20r=%273%27%3E%3C/circle%3E%3Cpath%20d=%27M17.4852814,3.51471863%20C15.3137085,1.34314575%2012.3137085,0%209,0%20C5.6862915,0%202.6862915,1.34314575%200.514718626,3.51471863%27%20id=%27Oval-3%27%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }
</style>
